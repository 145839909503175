<template>
    <div class="ranking-type-select"
         v-if="showRankingTypeSelect">
        <ul class="type-list">
            <li v-for="item of typeList"
                class="type-item"
                @click="changeRankingType(item)"
                :key="item.type">
                {{item.name}}
            </li>
        </ul>
    </div>
</template>

<script>
	import {mapState} from 'vuex'

	export default {
		name:"ranking-type-select",
        props:{
			showRankingTypeSelect:{
				type:Boolean,
                default:true
            }
        },
		data(){
			return {
				typeList:[
					{name:'总钟数排行',type:1},
					{name:'点钟数排行',type:2},
					{name:'加钟数排行',type:3}
          // {name:'总钟数排行',type:'total_clock'},
          // {name:'点钟数排行',type:'dot_clock'},
          // {name:'加钟数排行',type:'add_clock'},
				],
			}
		},
		computed:{
			...mapState([
				// 'showRankingTypeSelect'
			])
		},
		methods:{
			changeRankingType(item){
				let {showRankingTypeSelect} = this
				this.$emit("changeRankingType",showRankingTypeSelect)
				this.$store.commit('updateTechRankingType',item.type)
			}
		},
	}
</script>

<style lang="scss" type="text/scss" scoped>
    @import "~@/assets/style/public.scss";

    .ranking-type-select{
        position:absolute;
        z-index:3;
        right:34px;
        top:95px;
    }

    .ranking-type-select:before{
        position:absolute;
        left:50%;
        top:-16px;
        transform:translateX(-50%);
        content:'';
        width: 0;
        height: 0;
        border-width: 0 14px 20px;
        border-style: solid;
        border-color: transparent transparent #191919;
    }

    .type-list{
        background:#191919;
        border-radius:10px;
        color:#FFFFFF;
    }

    .type-item{
        border-bottom:1px solid #4D555E;
        padding:0 50px;
        height:66px;
        line-height:65px;
        font-size:26px;
        text-align:center;
    }

    .type-item:last-child{
        border:0;
    }

</style>
